<script>
import "./ChecklistContent.scss";
import ChecklistContent from "./ChecklistContent.js";

export default ChecklistContent;
</script>

<template>
  <form
    v-if="activeCheckList"
    class="checklistContent__wrapper"
    ref="contentForm"
  >
    <div
      class="checklistContent"
      v-for="checklist in activeCheckList.subcategories"
      :key="`${checklist.name}`"
    >
      <div class="checklistContent__header">
        <span class="checklistContent__header__title">
          {{ checklist.name }}
        </span>
      </div>

      <div
        v-for="(item, index) in checklist.fields"
        class="checklistContent__form"
        novalidate
        :key="`${item.id}-${index}`"
      >
        <div class="checklistContent__desc">
          <span class="checklistContent__desc__title"
            >{{ index + 1 }}. {{ hasField(item, "title") }}

          <span
              v-if="
              hasField(item, 'increase') !== 0 &&
              hasField(item, 'unit') !== undefined
              "
          >(min. {{ hasField(item, 'increase') }} {{ hasField(item, 'unit') }})</span>

          </span
          >
          <p class="checklistContent__desc__body">
            {{ hasField(item, "text") }}
          </p>
        </div>
        <div class="checklistContent__fieldset">
          <div
            v-if="
              hasField(item, 'unit') !== 'n.v.t' &&
              hasField(item, 'unit') !== undefined
            "
            class="checklistContent__field"
          >
            <span class="checklistContent__input__helper">{{
              hasField(item, "unit")
            }}</span>

            <input
              :id="`input-${item.id}`"
              class="checklistContent__input__field"
              :disabled="hasField(item, 'unit') == 'n.v.t'"
              :oninput="($event) => handleInputChange($event, item.id)"
              @focus="(e) => e.target.select()"
              :defaultValue="setDefaultInput(item.id)"
              maxlength="5"
              :min="hasField(item, 'increase')"
              type="number"
              required
            />
          </div>

          <div class="checklistContent__checkset">
            <div class="checklistContent__checkfield">
              <div v-if="hasField(item, 'remarks') != undefined">
                <button
                  @click.prevent="handleCommentsClick(item)"
                  :class="[
                    'checklistContent__button',
                    { 'has-content': checkItemForRemarks(item) },
                  ]"
                >
                  <span class="checklistContent__button__symbol">
                    <Svg-icon
                      v-if="checkItemForRemarks(item)"
                      iconType="Warning"
                    />
                    <Svg-icon v-else iconType="Pencil" />
                  </span>
                </button>
              </div>
            </div>

            <div
              v-if="hasField(item, 'check')"
              class="checklistContent__checkfield"
            >
              <input
                @change="($event) => handleCheckChange($event, item.id)"
                :id="`check-${item.id}`"
                :value="`check-${item.id}`"
                :class="[
                  {
                    'is-valid': itemIsSelected(item.id),
                  },
                  'checklistContent__checkbox',
                ]"
                type="checkbox"
                required
                :checked="itemIsSelected(item.id)"
              />

              <label
                :for="`check-${item.id}`"
                class="checklistContent__checkFaker"
              >
                <div class="checklistContent__check__symbol">
                  <span class="checklistContent__check__theSymbol">
                    <Svg-icon iconType="Check" />
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
