<script>
import "./MalfunctionsConfirm.scss";
import MalfunctionsConfirm from "./MalfunctionsConfirm.js";

export default MalfunctionsConfirm;
</script>

<template>
  <div class="malfunctionsConfirm">
    <transition name="fade" mode="in-out">
      <div class="malfunctionsConfirm__inner">
        <h3 class="heading heading--3 malfunctionsConfirm__title">
          Storing op traject,
        </h3>

        <div class="malfunctionsConfirm__content">
          <div class="malfunctionsConfirm__flightInfo">
            <div class="malfunctionsConfirm__flightInfo__item">
              <span class="malfunctionsConfirm__flightInfo__item__symbol">
                <Svg-icon iconType="Calendar-flat"></Svg-icon>
              </span>

              <span class="malfunctionsConfirm__flightInfo__item__label">{{
                now()
              }}</span>
            </div>

            <div class="malfunctionsConfirm__flightInfo__item">
              <span class="malfunctionsConfirm__flightInfo__item__symbol">
                <Svg-icon iconType="Anchor-circle"></Svg-icon>
              </span>

              <span class="malfunctionsConfirm__flightInfo__item__label">{{
                $store.state.flightSettings.shift.name
              }}</span>
            </div>

            <div class="malfunctionsConfirm__flightInfo__item">
              <span class="malfunctionsConfirm__flightInfo__item__symbol">
                <Svg-icon iconType="Ship-front"></Svg-icon>
              </span>

              <span class="malfunctionsConfirm__flightInfo__item__label">{{
                $store.state.flightSettings.ship.name
              }}</span>
            </div>
          </div>

          <div class="malfunctionsConfirm__remark">
            <span class="malfunctionsConfirm__remark__label">{{
              $store.state.newMalfunction.situation.title
            }}</span>

            <div class="malfunctionsConfirm__remark__box">
              <span class="malfunctionsConfirm__remark__box__content">{{
                $store.state.newMalfunction.remarks
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
